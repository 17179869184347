// RESET CSS

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

// WEBSITE STYLING
:root {
  --beige: #f4eddf;
  --white: #ffffff;
  --dark: rgba(30, 30, 30);
  --semidark: rgb(45, 45, 45);
}

/*app*/

.app {
  background-image: url("./assets/images/background2.jpg");
  background-size: contain;
  height: 100vh;
  font-family: "Poppins", sans-serif;
  color: rgb(27, 27, 27);

  &__container {
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  &__navbar {
    flex: 0 1 auto;
  }

  &__content {
    flex: 1 1 auto;
  }

  &__footer {
    flex: 0 1 auto;
  }

  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.content {
  padding: 50px 0 0 0;
  // margin: 5vh auto 0;
  height: 100%;

  background-color: var(--white);
  border-radius: 50px 50px 0 0;
  box-shadow: 0.5px 0.5px 10px 1px rgba(30, 30, 30, 0.2);

  &__inside {
    padding: 20px 50px;
  }
}

.block {
  margin: 20px;
}

.btn {
  margin: 15px auto;
  cursor: pointer;
  border:rgb(214, 213, 213) 1px solid;
  border-radius: 15px;
  background-color: var(--beige);
  font-family: "Poppins", sans-serif;

  &:active {
    background-color: var(--semidark);
    color: var(--white);
  }

  &__icon{
    margin-right: 10px;
  }

  &--call{
    margin: 30px auto;
  }

  &--handwritten {
    font-family: "Sacramento", cursive;
  }

  &--large {
    width: 250px;
    padding: 10px 20px;
    font-size: 1rem;
  }

  &--medium {
    width: 250px;
    height: 50px;
    padding: 8px 25px;
    font-size: 1.1rem;
  }

  &--small {
    padding: 6px 20px;
    font-size: 1rem;
  }
}

.map {
  margin: 30px 0;
}

.tag {
  border-radius: 25px;
  font-size:x-small;
  background-color: rgb(146, 34, 68);
  color: white;
  padding: 0 5px;
  margin: 5px;
}
/*fonts*/

a {
  color: var(--dark);
  text-decoration: none;
}

.page {
  text-align: center;
  padding-top: 80px;
}

.text {
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 10px;
}

.bold {
  font-weight: bold;
}

.underlined{
  text-decoration: underline;
}

.title {
  font-size: 2.5rem;
  font-family: "Sacramento", cursive;
  color: var(--primary);
  margin: 20px 0;
}

.title--medium {
  font-weight: 600;
  font-size: 2.2rem;
}

.title--small {
  font-weight: 400;
  font-size: 28px;
}

.title--nano {
  font-weight: 400;
  font-size: 14px;
}

.underlined {
  text-decoration: underline;
  text-underline-offset: 3px;
}

.landing{
    &__cards{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 30px auto;
    }

    &__card{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        border: 1px solid var(--dark);
        border-radius: 15px;
        padding: 10px;
        margin: 5px;

        width: 30%;
        height: 75px;


        &__icon{
            font-size: 1.8rem;
            margin-bottom: 5px;
            color: var(--dark);
        }
    }

    &__card:hover{
        cursor: pointer;
    }

    &__img{
        margin: 27px;
        width: 60%;

    }
}

@media screen and (min-width: 900px) {
.landing{
    &__img{
        width: 25%;
    }
}
}
